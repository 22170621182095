@import '../../scss/2.colors';
@import '../../scss/4.fontSize';

.logo{
    max-width: 2vmax;
}

.PageTemplate {
    margin-top: calc(2rem + 2.8vmax);
    min-height: 100vh;
    width: 100vw;
    background-color: getColor(color_background);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5vh;
    align-items: center;

    &_title {
        width: 100%;
        height: calc(2rem + 2.8vmax);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: getColor(color_footer_background);
        color: getColor(color_white);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        font-size: getFontSize(xl);
    }

    &_box {
        margin-top: 1vmax;
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
        font-weight: bolder;
        font-size: getFontSize(s) !important;
    }
}

