@import '../../scss/2.colors';
@import '../../scss/4.fontSize';

.logo{
    max-width: 2vmax;
}

.PageTemplate {
    margin-top: calc(2rem + 2.8vmax);;
    min-height: 100vh;
    width: 100vw;
    background-color: getColor(color_background);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &_title {
        width: 100%;
        height: calc(2rem + 2.8vmax);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: getColor(color_footer_background);
        color: getColor(color_white);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        font-size: getFontSize(xl);
    }

    &_box {
        display: flex;
        flex-direction: column;
        width: 90%;
        justify-content: center;
        align-items: center;
        font-weight: bolder;
        font-size: getFontSize(s) !important;
    }
}

.hero {
    min-height: 90vh;
    width: 100vw;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;

    &_content {
        text-align: center;
        color: #fff;
        width: 100%;
        height: 100vh;
    }

    h1 {
        font-size: 48px;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 24px;
        margin-bottom: 40px;
    }
}

.services {
    background-color: getColor(color_white);
    padding: 10vh 0;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    width: 90%;

    h3 {
        text-align: center;
        font-size: 36px;
        margin-bottom: 60px;
    }
}

.service {
    text-align: center;
    margin-bottom: 40px;
    opacity: 0.05;

    img {
        width: 200px;
        height: 200px;
        margin-bottom: 20px;
        border-radius: 50%;
    }

    h4 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    p {
        font-size: 18px;
        line-height: 1.5;
    }
}