@import '../../scss/2.colors';

.Account {
    min-height: 100vh;
    background-color: getColor(color_background);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    &_box {
        width: 50%;
        min-height: 25vh;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: getColor(color_white);
        border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
}