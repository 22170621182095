@import '../../scss/2.colors';
@import '../../scss/4.fontSize';

.logo{
    max-width: 2vmax;
}

.PageTemplate {
    margin-top: calc(2rem + 2.8vmax);
    min-height: 100vh;
    width: 100vw;
    background-color: getColor(color_background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_title {
        width: 100%;
        height: calc(2rem + 2.8vmax);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: getColor(color_footer_background);
        color: getColor(color_white);
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        font-size: getFontSize(xl);
    }

    &_box {
        display: flex;
        width: 90%;
        justify-content: center;
        align-items: center;
        font-weight: bolder;
        font-size: getFontSize(s) !important;

    }
}

.tel{
    text-decoration: none;
    color: inherit;
}

.contact_container {
    width: 90%;
    margin: 0 auto;
    padding: 40px;
    background-color: getColor(color_white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 4vmax;
}

.contact_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 2vmax;

    p{
        margin-top: 5px;
    }
}

.social_media {
    margin-top: 2vmax;
    display: flex;
    flex-direction: column;
    row-gap: 1vmax;
    align-items: center;
    justify-content: center;

    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 2vmax;

        li{
            display: inline;
        }
    }
}

.social_media ul li a img {
    max-width: 30px;
}

.working_hours{
    display: flex;
    flex-direction: column;
    row-gap: 1vmax;
    align-items: center;
}